/*
 *  RegisterPage.js
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/

import React from "react";
import './RegisterPage.css'
import Context from "../../context";
import { withTranslation } from 'react-i18next';
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import api from "../../hooks/api";


const RegisterPage = (props) => {
    const { t, showLogin } = props;
    const [showPassword, setShowPassword] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate()

    const doRegister = (event) => {
        setLoading(true)
        // const errorMessageElementFirstName = document.getElementById('error-message-firstname');
        // const errorMessageElementLastName = document.getElementById('error-message-lastname'); 
        const errorMessageElementUser = document.getElementById('error-message-user');
        const errorMessageElementEmail = document.getElementById('error-message-mail');
        const errorMessageElementPasswordValid = document.getElementById('error-message-pwvalid');
        const errorMessageElementPasswordRepeat = document.getElementById('error-message-pwrepeat');
        

        // Clear previous error message
        // errorMessageElementFirstName.textContent = '';
        // errorMessageElementFirstName.style.display = 'none';

        // Clear previous error message
        // errorMessageElementLastName.textContent = '';
        // errorMessageElementLastName.style.display = 'none'; 

        // Clear previous error message
        errorMessageElementUser.textContent = '';
        errorMessageElementUser.style.display = 'none';

        // Clear previous error message
        errorMessageElementEmail.textContent = '';
        errorMessageElementEmail.style.display = 'none';

        // Clear previous error message
        errorMessageElementPasswordValid.textContent = '';
        errorMessageElementPasswordValid.style.display = 'none';

        // Clear previous error message
        errorMessageElementPasswordRepeat.textContent = '';
        errorMessageElementPasswordRepeat.style.display = 'none';
 
        
        const username = document.getElementById('username').value;
        if ( username == "" ) {
            setLoading(false)
            errorMessageElementUser.textContent = t("ENTER_USERNAME"); // Assuming t is a translation function
            errorMessageElementUser.style.display = 'block'; // Show the error message
            return;
        }

        const email = document.getElementById('email').value;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            setLoading(false)
            errorMessageElementEmail.textContent = t("INVALID_MAILFORMAT"); // Assuming t is a translation function
            errorMessageElementEmail.style.display = 'block'; // Show the error message
            return;
        }

        const password = document.getElementById('password').value;
        var pwOk = true;
        if ( password.match( /[^a-zA-Z0-9#*+-~@$%&!]/ )) pwOk = false;
        if ( !password.match( /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#|*|+|-|~|@|$|%|&|!])[0-9a-zA-Z#*+-~@$%&!]{8,}$/ )) pwOk = false;
        if ( !pwOk ) {
            setLoading(false)
            errorMessageElementPasswordValid.textContent =  t("ENTER_VALID_PASSWORD"); // Assuming t is a translation function
            errorMessageElementPasswordValid.style.display = 'block'; // Show the error message
            return;
        }

        const passwordRpt = document.getElementById('passwordRpt').value;
        if ( password != passwordRpt ) {
            setLoading(false)
            errorMessageElementPasswordRepeat.textContent = t("PASSWORDS_DONT_MATCH"); // Assuming t is a translation function
            errorMessageElementPasswordRepeat.style.display = 'block'; // Show the error message
            return;
        }

        const activationKey = Context.generateToken( 16);

        var o = {
            username     : username,
            email        : email,
            orgaId        : 1,
            password     : password,
            mailSubject  : t("ACCOUNT_ACTIVATION"),
            activationkey: activationKey,
            language     : "de_de",
            mailBody     : t("MAIL_BODY_REGISTER", { "LINK" : Context.frontendURL+ "activate-account?activationKey="+ activationKey })
        };
        api.post('/v1/users', false,  o ).then( res => {
            setLoading(false)
            toast.success( t("ACCOUNT_WAS_CREATED") );
            navigate("/")
        }).catch( ( error ) => {
            setLoading(false)
            console.log(error)
            Context.alertError( error );
        });
    }
    
    const handleShowPassword = () => {
        const checkbox = document.getElementById('hide-password');
        console.log(checkbox.checked);
        console.log("document.getElementById('hide-password').value");
             setShowPassword(!showPassword);
     }

    return (
        <div id="divRegisterOuter" className="stddlg-outer"> 
            <form>
                <div className="flex-container">
                    <div className="row" style={{ textAlign: 'left' }}>
                        <h1 style={{ textAlign: 'left' }}>{t("REGISTRATION")}</h1>
                    </div>
                    <div className="row">
                    <div className="column"> 
                        <p>
                        <input className="loginfeld" placeholder={t("USERNAME")} type="text" name="username" id="username" required />
                        </p>
                        <div>
                        <span id="error-message-user" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <p> 
                            <input className="loginfeld" placeholder={t("MAIL")} type="email" name="email" id="email" required /> {/* Changed to email type */}
                        </p>
                        <div>
                        <span id="error-message-mail" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <p>
                            <input className="loginfeld" placeholder={t("PASSWORD")} type={showPassword?"text":"password"} name="password" id="password" required />
                        </p>
                        <div>
                            <span id="error-message-pwvalid" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <p className="show-password" onClick={handleShowPassword}>
                        <input className="hide-password" placeholder={t("hude")} type="checkbox" name="hide-password" id="hide-password"  checked={showPassword} required onChange={handleShowPassword} /> {t("SHOW_PASSWORD")}
                        </p>
                        <p>
                            <input className="loginfeld" placeholder={t("PASSWORD_RPT")} type={showPassword?"text":"password"}  name="passwordRpt" id="passwordRpt" required /> 
                        </p>
                        <div>
                        <span id="error-message-pwrepeat" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <br />
                        <p>
                            <Button buttonValue={t("CREATE_ACCOUNT")} loading={loading} buttonFunction={doRegister} />
                        </p>
                        <br />
                        <a href="login" className="clickable-link" >{t("SWITCH_LOGIN")}</a> 
                    </div>
                    
                    <div className="column">
                        <p>
                            <div dangerouslySetInnerHTML={{ __html: t('PASSWORD_RULES') }} />
                            <br /><br />
                        </p>
                    </div>
                    </div>
                </div>
            </form>
        </div> 
    );
}

export default withTranslation(['registerPage', 'common'] )(RegisterPage);
