/******************************************************************************
 *                                                                            *
 *  ProfilePage.js                                                            *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React, {useEffect} from "react";
// import './ProfilePage.css'
import Context from "../../context";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

import Button from "../../components/Button";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import { Loading } from "../../components/Loading";
import backEndData from "../../components/backEndData";
import axios from "axios";
import { useNavigate , Link} from 'react-router-dom'
import api from "../../hooks/api";

const OrganizationPage = (props) => {
    const { t } = props;
    const navigate = useNavigate()

    const [data, setData] = React.useState([]);
    const [userList, setUserList] = React.useState([]);
    const [edit, setEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    useEffect( () => { 
        setLoading(true) 
        api.get('/v1/organizations', true).then( res => { 
             setData( res.data );
             console.log(res.data)
        }).catch( ( error ) => {
            setLoading(false)  
            Context.alertError( error );
        });
        api.get('/v1/users', true).then( res => { 
             setUserList( res.data );
             console.log(res.data)
             setLoading(false)

        }).catch( ( error ) => {
            setUserList(backEndData.users);
            setLoading(false)
            Context.alertError( error ); 
        });
    }, []);

    const changeLanguage = (language) => {
        setData( Object.assign( {}, data, {language : language})  );
        Context.language = language;
        Cookies.set( 'language', language );
        i18n.changeLanguage( Context.language );
    }; 


    const doUpdateUser = () => {
        setLoading(true)
        if ( data.username === "" ) {
            setLoading(false);
            toast.warning( t("ENTER_USERNAME"));
            return;
        }

        const email = data.email;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            setLoading(false);
            toast.warning( t("MAILFORMAT_INVALID") )
            return;
        } 
    }



    return (
        <>
        {loading
        ?
        <Loading />
        :
        <div className="home-iframe body"> 
            <div class='page-content-1'> 
                <div class='container'> 
                    <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    >
                        <Tab eventKey="profile" title={t("ORGANIZATIONS")}> 
                            <p className="text-end">
                                <Link className="btn btn-primary" to={"create-organization"}>{t("CREATE_ORGANIZATION")}</Link>
                                {/* <button className="btn btn-primary"  onClick={props.showCreateOrganizationPage}></button> */}
                            </p>
                            <div class="table-responsive">
                                <table class="table">
                                    {/* <caption>List of users</caption> */}
                                    <thead>
                                        <tr>
                                        <th scope="col">#</th>
                                        {/* <th scope="col">.</th> */}
                                        <th scope="col">{t("ORGANIZATION_NAME")}</th>
                                        <th scope="col">{t("ORGANIZATION_DESCRIPTION")}</th>
                                        <th scope="col">{t("ORGANIZATION_PREFIX")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.reverse().map((organization, index)=>(
                                            <tr onClick={()=>{navigate(`organization-detail/${organization.id}`)}}>
                                                <th scope="row">{index}</th>
                                                {/* <td>Mark</td> */}
                                                <td>{organization.name}</td>
                                                <td>{organization.description??"Lorem ipsum mann brauche description"}</td>
                                                <td>{organization.prefix}</td>
                                            </tr> 
                                        ))} 
                                    </tbody>
                                </table>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title={t("USERS")}> 
                        <p className="text-end">
                                <button className="btn btn-primary" onClick={props.showRegisterPage} >{t("CREATE_USER")}</button>
                            </p>
                        <div class="table-responsive">
                                <table class="table">
                                    {/* <caption>List of users</caption> */}
                                    <thead>
                                        <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{t("USERNAME")}</th>
                                        <th scope="col">{t("MAIL")}</th>
                                        <th scope="col">{t("ENABLED")}</th>
                                        <th scope="col">{t("ACTION")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userList?.map((user, index)=>(
                                            <tr>
                                                <th scope="row">{index}</th>
                                                <td>{user.username}</td>
                                                <td>{user.email}</td>
                                                <td>{user.enabled}</td>
                                                <td>{user.lastlogin}</td>
                                            </tr>
                                        ))} 
                                    </tbody>
                                </table>
                            </div>
                        </Tab>
                    </Tabs> 
                        
                    </div> 
            </div> 
        </div>  
        }

            
            
        </>
    );
}

export default withTranslation(['admin', 'common'] )(OrganizationPage);
