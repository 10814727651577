// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #80c5ed; /* Primary blue color */
    color: #000000; /* Text color */
    padding: 12px; /* Padding for the button */
    border: none; /* No border */
    border-radius: 8px; /* Rounded corners */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Cursor style */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for background and scale */
}

.button-loading {
    height: 16px;
    width: 16px;
    border: 2px solid white;
    border-top: 2px solid blue;
    border-radius: 50%;
    animation: loading 1s infinite linear;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Button hover effect */
.button-component:hover {
    background-color: #74aeec; /* Darker blue when hovered */
    transform: scale(1.03); /* Slightly increase the size on hover */
}

/* Button active effect */
.button-component:active {
    transform: scale(0.97); /* Slightly decrease the size when pressed */
}`, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,yBAAyB,EAAE,uBAAuB;IAClD,cAAc,EAAE,eAAe;IAC/B,aAAa,EAAE,2BAA2B;IAC1C,YAAY,EAAE,cAAc;IAC5B,kBAAkB,EAAE,oBAAoB;IACxC,eAAe,EAAE,cAAc;IAC/B,eAAe,EAAE,iBAAiB;IAClC,2DAA2D,EAAE,+CAA+C;AAChH;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,0BAA0B;IAC1B,kBAAkB;IAClB,qCAAqC;AACzC;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA,wBAAwB;AACxB;IACI,yBAAyB,EAAE,6BAA6B;IACxD,sBAAsB,EAAE,wCAAwC;AACpE;;AAEA,yBAAyB;AACzB;IACI,sBAAsB,EAAE,4CAA4C;AACxE","sourcesContent":[".button-component {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    background-color: #80c5ed; /* Primary blue color */\n    color: #000000; /* Text color */\n    padding: 12px; /* Padding for the button */\n    border: none; /* No border */\n    border-radius: 8px; /* Rounded corners */\n    font-size: 16px; /* Font size */\n    cursor: pointer; /* Cursor style */\n    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for background and scale */\n}\n\n.button-loading {\n    height: 16px;\n    width: 16px;\n    border: 2px solid white;\n    border-top: 2px solid blue;\n    border-radius: 50%;\n    animation: loading 1s infinite linear;\n}\n\n@keyframes loading {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n/* Button hover effect */\n.button-component:hover {\n    background-color: #74aeec; /* Darker blue when hovered */\n    transform: scale(1.03); /* Slightly increase the size on hover */\n}\n\n/* Button active effect */\n.button-component:active {\n    transform: scale(0.97); /* Slightly decrease the size when pressed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
