/*
 *  HomePage.js
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
import React from "react";
import './HomePage.css'
import { withTranslation } from 'react-i18next';

const HomePage = (props) => {
    const { t } = props;

    return ( 
        <div className="home-page">
        <div className="overlay">
         </div>
        <div className="container row">
            <div className="card-container col-md-4">
                <p  className="text-center" >{t("OFFERS")}</p>
                <div className="container ">
                    <div className="card">
                        <img className="card-image-top" src="/card-img1.jpeg" alt="" /> 
                        <div className="card-body"> 
                            <h3>Header</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae vitae nesciunt iure id hic expedita, eos sed.  </p>
                        </div>
    
                    </div>
                    <div className="card">
                        <img className="card-image-top" src="/card-img1.jpeg" alt="" />
                        
                        <div className="card-body"> 
                            <h3>Header</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae vitae nesciunt iure id hic expedita, eos sed.  </p>
                        </div>
    
                    </div> 
                </div> 
                
            </div>
            <div className="card-container col-md-4">
                <p className="text-center">{t("INFORMATION")}</p>
                <div className="container">
                    <div className="card" >
                        <div className="card-profile card-header"> 
                            <img className="card-image-profile" src="/face.jpeg"  alt="" />
                            <div className="info-card-header" >
                                <b>Category</b>
                                <p>Author</p>
                            </div>
                        </div>
                        <div className="card-body"> 
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae vitae nesciunt iure id hic expedita, eos sed.  </p>
                        </div> 
                    </div>
                    <div className="card info" >
                        <div className="card-profile card-header"> 
                            <img className="card-image-profile" src="/face.jpeg" alt="" />
                            <div className="info-card-header" >
                                <b>Category</b>
                                <p>Author</p>
                            </div>
                        </div>
                        <div className="card-body"> 
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae vitae nesciunt iure id hic expedita, eos sed.  </p>
                        </div> 
                    </div> 
                </div>
            </div>
            <div className="card-container col-md-3">
                <div className="widget">
                {/* <p className="text-center">INFORMATION</p> */}
                </div>
                <div className="news"></div>
                <div className="wish-box"></div>
                <div className="chat"></div>
            </div>
        </div>

    </div>
    );
}

export default withTranslation(['homePage', 'common'] )(HomePage);
