/******************************************************************************
 *                                                                            *
 *  ProfilePage.js                                                            *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React, {useEffect} from "react";
import './ProfilePage.css'
import Context from "../../context";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import api from "../../hooks/api";

const ProfilePage = (props) => {
    const { t } = props;

    const [data, setData] = React.useState({});
    const [edit, setEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [name,   setName ] = React.useState("")
    useEffect( () => { 
        api.get(`/v1/users/` + Cookies.get("userId"), true).then( res => {
            if ( res.data.shownotifications === '' ) {
                res.data.shownotifications = "D";
            }
            console.log(res.data)
            setName(`${res.data.firstname} ${res.data.lastname}`)
            console.log(Context.password)
            res.data.currentUsername = Context.username; 
            Context.userId = res.data.id;
            setData( res.data );
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }, []);

    const changeLanguage = (language) => {
        setData( Object.assign( {}, data, {language : language})  );
        Context.language = language;
        Cookies.set( 'language', language );
        i18n.changeLanguage( Context.language );
    }; 


    const doUpdateUser = () => {
        setLoading(true)
        if ( data.username === "" ) {
            setLoading(false);
            toast.warning( t("ENTER_USERNAME"));
            return;
        }

        const email = data.email;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            setLoading(false);
            toast.warning( t("MAILFORMAT_INVALID") )
            return;
        }

        api.put('/v1/users/'+Cookies.get("userId"),true,  data ).then( () => {
            setLoading(false);
            toast.success( t("PROFILE_WAS_SAVED"))
        }).catch( ( error ) => {
            setLoading(false);
            console.log(error)
            Context.alertError( error );

        });
    }

    return (
        <>
        <div className="home-iframe body"> 
                <div class='page-content-1'>
                    <div class='header-2'>
                        <div class='banner'>
                            <img src="/banner-3.png" alt="" />
                        </div>
                        <div class='profile-pic'>
                            <img class="profile" src="/pic-avatar.jpg" alt="" />
                        </div>
                        <div class='profile-name'>
                            {name}
                        </div>
                    </div>
                    <div class='container'>
                        {/* <div class='tab-navbar'>
                            <ul class="tab-items">
                                <li class="tab-item active">User</li>
                                <li class="tab-item">Beifrage</li>
                                <li class="tab-item">Kommentaire</li>
                            </ul>
                        </div> */}
                        <Tabs
                        defaultActiveKey="profile"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        >
                            {/* <Tab eventKey="home" title="Home">
                                Tab content for Home
                            </Tab> */}
                            <Tab eventKey="profile" title={t("PROFILE")} className="table-responsive">
                                <table className="table"> 
                                    <tbody>
                                        <tr>
                                            <td>  {t("USERNAME")} </td>
                                            <td>  {data.username}  </td>
                                        </tr>
                                        <tr>
                                            <td>  {t("MAIL")} </td>
                                            <td>  {data.email} </td>
                                        </tr>
                                        <tr>
                                            <td>  {t("LASTNAME")} </td>
                                            <td>  {data.lastname}  </td>
                                        </tr>
                                        <tr>
                                            <td>  {t("FIRSTNAME")} </td>
                                            <td>  {data.firstname}  </td>
                                        </tr>
                                        <tr>
                                            <td>  {t("CITYCODE")} </td>
                                            <td>  {data.postcode}  </td>
                                        </tr>
                                        <tr>
                                            <td>  {t("CITY")} </td>
                                            <td>  {data.city}  </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>  {t("ADDRESS")} </td>
                                            <td>  {data.address}  </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>  {t("TELEPHONE")} </td>
                                            <td>  {data.telephone}  </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>  {t("MOBILE")} </td>
                                            <td>  {data.mobile}  </td>
                                        </tr>
                                        
                                        
                                        <tr>
                                            <td>  {t("SEND_MESSAGES")} </td>
                                            <td> 
                                                <select  size="1" value={data.shownotifications} disabled={!edit}  onChange={e => setData( Object.assign( {}, data, {shownotifications : e.target.value}))}>
                                                    <option value="N">{t("MSG_DISABLE")}</option>
                                                    <option value="H">{t("MSG_HOURLY")}</option>
                                                    <option value="D">{t("MSG_DAILY")}</option>
                                                    <option value="W">{t("MSG_WEEKLY")}</option>
                                                </select>
                                            </td>
                                        </tr>                                        
                                        
                                        <tr>
                                            <td>  {t("SHOW_INTRO")}</td>
                                            <td>    <input type="checkbox" checked={data.showintro} disabled  />  </td>
                                        </tr>
                                        
                                        
                                        <tr>
                                            <td>  {t("SHOW_HELPBUTTONS")} </td>
                                            <td>  <input type="checkbox" checked={data.showhelp} disabled  /> </td>
                                        </tr>
                                        
                                        
                                        <tr>
                                            <td>  {t("SHOW_CLEARNAME")} </td>
                                            <td>  <input type="checkbox" checked={data.showclearname} disabled  /> </td>
                                        </tr>
                                        
                                        
                                        <tr>
                                            <td>  {t("SHOW_CONTACTS")} </td>
                                            <td>  <input type="checkbox" checked={data.showcontact} disabled  /> </td>
                                        </tr> 

                                        
                                        
                                        
                                        <tr>
                                            <td>  {t("LANGUAGE")} </td>
                                            <td>
                                                <select  size="1" value={data.language} disabled>
                                                    <option value="de_de">Deutsch</option>
                                                    <option value="en_gb">English</option>
                                                    <option value="es_es">Español</option>
                                                    <option value="fr_fr">Français</option>
                                                </select>
                                            </td>
                                        </tr> 
                                    </tbody>

                                </table>
                                <p style={{display:!edit?"block":"none"}}>
                                    {/* <input type="button" onClick={ () => setEdit(!edit) } value={t("EDIT")}/>  */}
                                </p>
                            </Tab>
                            <Tab eventKey="contact" title={t("MODIFY_PROFILE")} className="table-responsive">
                                <table className="table"> 
                                    <tbody>
                                        <tr>
                                            <td>  {t("USERNAME")} </td>
                                            <td> 
                                                <input class="input" type="text" value={data.username} disabled={edit} onChange={e => setData( Object.assign( {}, data, {username : e.target.value}))} required/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>  {t("MAIL")} </td>
                                            <td> <input class="input" type="text" value={data.email} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {email : e.target.value}))} required/>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>  {t("LASTNAME")} </td>
                                            <td> <input class="input" type="text" value={data.lastname} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {lastname : e.target.value}))} />

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>  {t("FIRSTNAME")} </td>
                                            <td> 
                                                <input class="input" type="text" value={data.firstname} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {firstname : e.target.value}))} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>  {t("CITYCODE")} </td>
                                            <td>
                                                <input class="input" type="text" value={data.postcode} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {postcode : e.target.value}))} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>  {t("CITY")} </td>
                                            <td>
                                                <input class="input" type="text" value={data.city} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {city : e.target.value}))} />

                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>  {t("ADDRESS")} </td>
                                            <td>
                                                 <input class="input" type="text" value={data.address} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {address : e.target.value}))} />

                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>  {t("TELEPHONE")} </td>
                                            <td>
                                                <input class="input" type="text" value={data.telephone}  disabled={edit}  onChange={e => setData( Object.assign( {}, data, {telephone : e.target.value}))} />

                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>  {t("MOBILE")} </td>
                                            <td>
                                                <input class="input" type="text" value={data.mobile} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {mobile : e.target.value}))} />

                                            </td>
                                        </tr>
                                        
                                        
                                        <tr>
                                            <td>  {t("SEND_MESSAGES")} </td>
                                            <td> 
                                                <select name="shownotifications" id="shownotifications" size="1" value={data.shownotifications} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {shownotifications : e.target.value}))}>
                                                <option value="N">{t("MSG_DISABLE")}</option>
                                                <option value="H">{t("MSG_HOURLY")}</option>
                                                <option value="D">{t("MSG_DAILY")}</option>
                                                <option value="W">{t("MSG_WEEKLY")}</option>
                                            </select>
                                            </td>
                                        </tr>                                        
                                        
                                        <tr>
                                            <td>  {t("SHOW_INTRO")}</td>
                                            <td> 
                                            <input type="checkbox" id="showintro" name="showintro" checked={data.showintro} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {showintro : e.target.value}))} />

                                            </td>
                                        </tr>
                                        
                                        
                                        <tr>
                                            <td>  {t("SHOW_HELPBUTTONS")} </td>
                                            <td>
                                            <input type="checkbox" id="showhelp" name="showhelp" checked={data.showhelp} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {showhelp : e.target.value}))} />

                                            </td>
                                        </tr>
                                        
                                        
                                        <tr>
                                            <td>  {t("SHOW_CLEARNAME")} </td>
                                            <td>
                                            <input type="checkbox" id="showclearname" name="showclearname" checked={data.showclearname} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {showclearname : e.target.value}))} />

                                            </td>
                                        </tr>
                                        
                                        
                                        <tr>
                                            <td>  {t("SHOW_CONTACTS")} </td>
                                            <td>
                                            <input type="checkbox" id="showcontact" name="showcontact" checked={data.showcontact} disabled={edit}  onChange={e => setData( Object.assign( {}, data, {showcontact : e.target.value}))} />

                                            </td>
                                        </tr>  
                                        <tr>
                                            <td>  {t("LANGUAGE")} </td>
                                            <td>
                                            <select name="language" id="language" size="1" value={data.language} disabled={edit}  onChange={e => changeLanguage(e.target.value)}>
                                                <option value="de_de">Deutsch</option>
                                                <option value="en_gb">English</option>
                                                <option value="es_es">Español</option>
                                                <option value="fr_fr">Français</option>
                                            </select>
                                            </td>
                                        </tr> 
                                    </tbody> 
                                </table>
                                <p className="text-center" style={{display:"flex", justifyContent:"center"}}>
                                    {/* <input style={{backgroundColor:!edit?"blue":"red"}} type="button" onClick={ () => setEdit(!edit) } value={edit?t("Cancel"):t("Edit")}/> */}
                                    <Button style={{width:"50%"}} buttonValue={ t("SAVE_PROFILE")} buttonFunction={doUpdateUser} loading={loading} disabled={!edit} />
                                </p> 
                            </Tab>
                        </Tabs>
                             
                            {/* <b class="tab-content">Benutzername</b>
                            <p class="tab-content" >Hans-ZM</p>
                            <b class="tab-content">Benutzername</b>
                            <p class="tab-content">Hans-ZM</p>
                            <b class="tab-content">Benutzername</b>
                            <p class="tab-content">Hans-ZM</p>  */}

                            {/*  */}
                            {/* <div id="divRegisterOuter" className="stddlg-outer"> */}
                                {/* <div id="divRegister" className="stddlg"> */}
                                
                                    
                                    {/* <br/.0.0 */}
                                {/* </div> */}
                            {/* </div> */}
                            
                     </div>
                
                </div>

            </div>

            
            
        </>
    );
}

export default withTranslation(['profilePage', 'common'] )(ProfilePage);
