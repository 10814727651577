import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import LoginPage from "../pages/users/LoginPage";
import LogoutPage from "../pages/users/LogoutPage";
import ProfilePage from "../pages/users/ProfilePage";
import ChangePasswordPage from "../pages/users/ChangePasswordPage"; 
import RegisterPage from "../pages/users/RegisterPage";
import PasswordResetPage from "../pages/users/PasswordResetPage";
import DeleteAccountPage from "../pages/users/DeleteAccountPage";
import ActivationHandler from "../pages/activationHandler";

const isAuthenticated = () => !!localStorage.getItem("user"); // Example auth logic

const UserRoutes = () => {
    return (
        <Routes> 
            <Route path="login" element={<LoginPage />} />
            <Route
                path="register"
                element={<RegisterPage />}
            />
            <Route
                path="profile"
                element={isAuthenticated() ? <ProfilePage /> : <Navigate to="/user/login" />}
            />
            <Route
                path="change-password"
                element={isAuthenticated() ? <ChangePasswordPage /> : <Navigate to="/user/login" />}
            />
            <Route
                path="logout"
                element={isAuthenticated() ? <LogoutPage /> : <Navigate to="/user/login" />}
            />
            <Route
                path="password-reset"
                element={isAuthenticated() ? <PasswordResetPage /> : <Navigate to="/user/login" />}
            />
            <Route
                path="delete-account"
                element={isAuthenticated() ? <DeleteAccountPage /> : <Navigate to="/user/login" />}
            />

            {/* Activation handling (special pages) */}
            <Route path="/activate-account" element={<ActivationHandler action="activate" />} />
            <Route path="/reset-password" element={<ActivationHandler action="reset" />} />
            <Route path="/delete-account" element={<ActivationHandler action="delete" />} />

            <Route path="*" element={<p>404</p>} /> 

        </Routes>
    );
};

export default UserRoutes;


