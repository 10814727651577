import React, { useEffect } from 'react' 
import { Route, Routes, Navigate } from 'react-router-dom'
import ProfilePage from "../pages/users/ProfilePage"
import OrganizationPage from "../pages/organizations/OrganizationPage"
import OrganizationDetailPage from "../pages/organizations/OrganizationDetailPage"
import CreateOrganizationPage from "../pages/organizations/CreateOrganizationPage"
import Cookies from "js-cookie";
import { useAuth } from '../hooks/authContext'
import { Loading } from '../components/Loading'
import ModifyOrganizationPage from '../pages/organizations/ModifyOrganizationPage'
// Loading
const AdminRoutes = () => { 

    const {isAdmin, isLoggedIn, loading} = useAuth()
    if (loading) {
        return <Loading/>; // Replace with your loading indicator
    }

    // Redirect to login if not logged in
    if (!isLoggedIn) {
        return <Navigate to="/user/login" />;
    }

    console.log(isAdmin)

    return (
        <Routes>
            <Route path="/"   element={isAdmin ? <OrganizationPage /> : <Navigate to="/user/login" />}  />
            <Route 
                path="/organization-detail/:id" 
                element={isAdmin ? <OrganizationDetailPage /> : <Navigate to="/user/login" />} />

            <Route 
                path="/modify-organization/:id" 
                element={isAdmin ? <ModifyOrganizationPage /> : <Navigate to="/user/login" />} />

            <Route
                path="user-detail"
                element={isAdmin ? <ProfilePage /> : <Navigate to="/user/login" />}
            />
            <Route
                path="create-organization"
                element={isAdmin ? <CreateOrganizationPage /> : <Navigate to="/user/login" />}
            />
                {/* <Route path="*" element={<p>404</p>} /> */} 
        </Routes>
    )
}

export default AdminRoutes