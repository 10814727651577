/******************************************************************************
 *                                                                            *
 *  RegisterPage.js                                                           *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React, { useEffect } from "react";

import LoginPage from "../users/LoginPage";
import './CreateOrganizationPage.css';
import Context from "../../context";
import { withTranslation } from 'react-i18next';
import Button from "../../components/Button";
import { toast } from "react-toastify";

import { Link,Navigate, Router, useNavigate , useParams} from "react-router-dom"; 
import 'bootstrap/dist/css/bootstrap.min.css'; 
import api from "../../hooks/api";
import { Loading } from "../../components/Loading";

const ModifyOrganizationPage = (props) => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [data, setData] = React.useState({})
    const { t, showLogin } = props;
    const [showPassword, setShowPassword] = React.useState(false);
    const [file, setFile] = React.useState();
    const [loading, setLoading] = React.useState(true);

    

    useEffect(()=>{
        if (id) {
            setLoading(true)
            api.get('v1/organizations/'+id , true).then( res => { 
                setData( res.data );
                console.log(res.data)
            }).catch( ( error ) => {
                setData({})
                Context.alertError( error );
            }).finally(()=>{                
                setLoading(false)
            })
            ;
        } else {
            toast("ID not defined")
            navigate(-1)
        }
    }, [])
    

    const doUpdateOrganization = (event) => {
        setLoading(true)
        const errorMessageElementName = document.getElementById('error-message-name');
        const errorMessageElementLogo = document.getElementById('error-message-logo');
        const errorMessageElementDescription = document.getElementById('error-message-description'); 
        

        // Clear previous error message
        errorMessageElementName.textContent = '';
        errorMessageElementName.style.display = 'none';

        // Clear previous error message
        errorMessageElementLogo.textContent = '';
        errorMessageElementLogo.style.display = 'none';

        // Clear previous error message
        errorMessageElementDescription.textContent = '';
        errorMessageElementDescription.style.display = 'none'; 


        const name = document.getElementById('name').value;
        if ( name == "" ) {
            setLoading(false)
            errorMessageElementName.textContent = t("ENTER_ORGANIZATION_NAME"); // Assuming t is a translation function
            errorMessageElementName.style.display = 'block'; // Show the error message
            return;
        }

        const description = document.getElementById('description').value;
        if ( description == "" ) {
            setLoading(false)
            errorMessageElementDescription.textContent = t("ENTER_ORG_DESCRIPTION"); // Assuming t is a translation function
            errorMessageElementDescription.style.display = 'block'; // Show the error message
            return;
        }

        const logo = document.getElementById('logo').value;
        if ( logo == "" ) {
            setLoading(false)
            errorMessageElementLogo.textContent = t("UPLOAD_LOGO"); // Assuming t is a translation function
            errorMessageElementLogo.style.display = 'block'; // Show the error message
            return;
        }
        
        // const prefix = document.getElementById('prefix').value;
        // if ( prefix == "" ) {
        //     setLoading(false)
        //     errorMessageElementLogo.textContent = t("ENTER_ZIPCODE"); // Assuming t is a translation function
        //     errorMessageElementLogo.style.display = 'block'; // Show the error message
        //     return;
        // } 
        const activationKey = Context.generateToken( 16);

        var payload = {
            name            : name,
            logo            : logo,
            description     : description, 
            id              : 0, 
        };
        console.log(payload)
        api.put('/v1/organizations/'+id,true, payload ).then( res => {
            setLoading(false)
            toast.success( t("ORGANIZATION_WAS_UPDATED_SUCCESSFULLY") );
            navigate(-1);
        }).catch( ( error ) => {
            setLoading(false)
            console.log(error)
            Context.alertError( error );  
        });
    }  
    if (loading){
        return (<Loading />)
    }

return (
    <div id="divRegisterOuter" className="stddlg-outer"> 
                
        <form className="form">
            <div className="flex-container container">
                <div className="row">
                    <h1 style={{ marginLeft: '30px' }}>{t("UPDATE_ORGANIZATION")}</h1>
                </div>
                <div className="container">
                    <div className="column">
                        <p>
                            <input className="loginfeld" placeholder={t("ORGANIZATION_NAME")} type="text" name="name" value={data.name??""} id="name" required />
                        </p>
                        <div>
                            <span id="error-message-name" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <p>
                            <input className="loginfeld" placeholder={t("LOGO")} type="text"  name="logo" value={data.logo??""}  id="logo" required /> 
                        </p>
                        <div>
                            <span id="error-message-logo" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <p>
                            <textarea className="loginfeld" placeholder={t("DESCRIPTION")}  name="description" id="description" value={data.description??""}  required></textarea>  
                        </p>
                        <div>
                            <span id="error-message-description" className="error" style={{display:'none', color:'red'}}></span>
                        </div>  
                        <br />
                        <p> 
                            <Button buttonValue={t("UPDATE_ACCOUNT")} loading={loading} buttonFunction={doUpdateOrganization} />
                        </p>
                        <br /> 
                    </div> 
                </div>
            </div>
        </form>
    </div> 
);
};

export default withTranslation(['admin', 'common'] )(ModifyOrganizationPage);
