/*
 *  LoginPage.js
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
import React from "react";
import './LoginPage.css'
import Context from "../../context";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Button from "../../components/Button";
import { Link, useNavigate } from "react-router-dom"; 
import api from "../../hooks/api"; 
import { useAuth } from "../../hooks/authContext";





const LoginPage = (props) => {
    
    const { t } = props;
    const [loading, setLoading ] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false);
    const navigate = useNavigate() 
    

    
    const {login, setAdmin} = useAuth()


    const doLogin = () => {
        setLoading(true)
        const user = document.getElementById('username').value;
        const errorMessageElementUser = document.getElementById('error-message-user');
        const errorMessageElementPassword = document.getElementById('error-message-pw');
        
        // Clear previous error message
        errorMessageElementUser.textContent = '';
        errorMessageElementUser.style.display = 'none';

        // Clear previous error message
        errorMessageElementPassword.textContent = '';
        errorMessageElementPassword.style.display = 'none';

        if (user === "") {
            errorMessageElementUser.textContent = t("ENTER_USERNAME"); // Assuming t is a translation function
            errorMessageElementUser.style.display = 'block'; // Show the error message
            setLoading(false)
            return;
        } 

        const pw = document.getElementById('password').value;
        if ( pw === "" ) {
            errorMessageElementPassword.textContent = t("ENTER_PASSWORD"); // Assuming t is a translation function
            errorMessageElementPassword.style.display = 'block'; // Show the error message
            setLoading(false)
            return;
        }

        const o = {
            username: user,
            password: pw
        }
        api.post('/v1/users/login', false, o).then( res => { 
            console.log(res) 
            Context.language = "de_de"; 
            api.setCredentials(user, pw)  
            login(user)
            Cookies.set( 'language', res.data );
            Cookies.set('userId', res.data.id)
            console.log(pw+` /v1/roles/by-username/` + user)
            console.log("user ", user)
            api.get(`/v1/users/${res.data.id}/roles`, true).then((userDetails)=>{
                localStorage.setItem("user", userDetails)
                console.log("user details", userDetails.data)
                if (userDetails.data?.includes("DEVELOPER")||userDetails.data?.includes("ADMIN")) {
                    api.setCredentials(user, pw, true)    
                    setAdmin()
                } else {
                    api.setCredentials(user, pw) 
                    
                } 
                navigate("/")
                // props.setUser(userDetails) 
            }).catch((error)=>{ 
                api.setCredentials(user, pw)  
                navigate("/")
                console.log(error) 
                Context.alertError( error );                
            }).finally(
                setLoading(false)
            )
            // props.setUser(user);
            
        }).catch( ( error ) => {
            console.log(error)
            setLoading(false)
            Context.alertError( error );
        });
    }
    const handleShowPassword = () => {
        const checkbox = document.getElementById('hide-password');
        console.log(checkbox.checked);
        console.log("document.getElementById('hide-password').value");
             setShowPassword(!showPassword);
     }

    return (
        <div id="divLoginOuter" className="stddlg-outer">
            <div id="divLogin" className="stddlg">
                <div className="container">
                    <form >
                        <h1>Login</h1>
                        <p style={{marginBottom:'0px'}}>
                            <br/><input class="loginfeld" style={{marginTop:'8px'}} placeholder={t('USERNAME')} type="text" name="username" id="username" required/>
                        </p>
                        <div>
                            <span id="error-message-user" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <p style={{marginBottom:'0px'}}>
                            <input class="loginfeld" style={{marginTop:'16px'}} placeholder={t('PASSWORD')} type={showPassword?"text":"password"} name="password" id="password" required/>
                        </p>
                        <div>
                            <span id="error-message-pw" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <p className="show-password" onClick={handleShowPassword}>
                            <input className="hide-password" placeholder={t("hude")} type="checkbox" name="hide-password" id="hide-password"  checked={showPassword} required onChange={handleShowPassword} /> {t("SHOW_PASSWORD")}
                            </p>
                        
                        <br/>
                        <p>
                            {/* <input className="login-button" type="button" onClick={ () => doLogin() } value={t('LOGIN')}/> */}
                            <Button buttonValue={t('LOGIN')} buttonFunction={doLogin} loading={loading} />
                        </p>
                        <br/>
                        <a href="register" className="clickable-link"  >{t("SWITCH_REG")}</a>
                    </form>
                </div>
                <br/>
                <div class=" container ">
                    <div class="login-button ">
                        {t("HELP_CHAT")}
                    </div>
                    <br/>
                    <div className="login-button ">
                        {t("HELP_VIDEO")}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation(['loginPage', 'common'])(LoginPage);
