/*
 *  ChangePasswordPage.js
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
import React from "react";
import './ChangePasswordPage.css'
import Context from "../../context";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { toast } from "react-toastify";
import Button from "../../components/Button";
import api from "../../hooks/api";

const ChangePasswordPage = (props) => {
    const { t } = props;
    const [loading, setLoading] = React.useState(false)

    const doChangePassword = () => {
        const oldPassword = document.getElementById('oldPassword').value;
        const errorMessageElementPasswordCurrent = document.getElementById('error-message-pwcurrent');
        const errorMessageElementPasswordValid = document.getElementById('error-message-pwvalid');
        const errorMessageElementPasswordRepeat = document.getElementById('error-message-pwrepeat');
       
        // Clear previous error message
        errorMessageElementPasswordCurrent.textContent = '';
        errorMessageElementPasswordCurrent.style.display = 'none';

        // Clear previous error message
        errorMessageElementPasswordValid.textContent = '';
        errorMessageElementPasswordValid.style.display = 'none';

        // Clear previous error message
        errorMessageElementPasswordRepeat.textContent = '';
        errorMessageElementPasswordRepeat.style.display = 'none';

        if ( oldPassword === "" ) {
            errorMessageElementPasswordCurrent.textContent = t("ENTER_CURRENT_PASSWORD"); // Assuming t is a translation function
            errorMessageElementPasswordCurrent.style.display = 'block'; // Show the error message
            return;
        }

        const password = document.getElementById('password').value;
        var pwOk = true;
        if ( password.match( /[^a-zA-Z0-9#*+-~@$%&!]/ )) pwOk = false;
        if ( !password.match( /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#|*|+|-|~|@|$|%|&])[0-9a-zA-Z#*+-~@$%&!]{8,}$/ )) pwOk = false;
        if ( !pwOk ) {
            errorMessageElementPasswordValid.textContent = t("ENTER_VALID_PASSWORD"); // Assuming t is a translation function
            errorMessageElementPasswordValid.style.display = 'block'; // Show the error message
            return;
        }

        const passwordRpt = document.getElementById('passwordRpt').value;
        if ( password != passwordRpt ) {
            errorMessageElementPasswordRepeat.textContent = t("PASSWORDS_DONT_MATCH"); // Assuming t is a translation function
            errorMessageElementPasswordRepeat.style.display = 'block'; // Show the error message
            return;
        }

        var o = {
            username   : Context.username,
            oldPassword: oldPassword,
            password   : password
        }; 

        api.post('/v1/users/change-password', true, o  ).then( res => {
            Context.password = password;
            const encr = Context.encrypt( password );
            Cookies.set( 'pw', encr );
            toast.success( t("PASSWORD_HAS_BEEN_CHANGED" ));
            props.showHomePage();
        }).catch( ( error ) => {
            setLoading(false)
            Context.alertError( error );
        });

    }

    return (
        <div id="divRegisterOuter" className="stddlg-outer">
            <div id="divRegister" className="stddlg">
                <form >
                    <p>
                        <input type="password" className="loginfeld" placeholder={t("OLD_PASSWORD")} name="oldPassword" id="oldPassword" required/>
                    </p>
                    <div>
                            <span id="error-message-pwcurrent" className="error" style={{display:'none', color:'red'}}></span>
                    </div>
                    <br/>
                    <br/>
                    <div dangerouslySetInnerHTML={{__html: t('PASSWORD_RULES')}} />
                    <br/><br/>
                    <p>
                        <input type="password" className="loginfeld" placeholder={t("PASSWORD")} name="password" id="password" required/>
                    </p>
                    <div>
                            <span id="error-message-pwvalid" className="error" style={{display:'none', color:'red'}}></span>
                    </div>
                    <p>
                        <input type="password" className="loginfeld" placeholder={t("PASSWORD_RPT")} name="passwordRpt" id="passwordRpt" required/>
                    </p>
                    <div>
                            <span id="error-message-pwrepeat" className="error" style={{display:'none', color:'red'}}></span>
                    </div>
                    <br/>
                    <p>
                        <Button buttonValue={t("CHANGE_PASSWORD")} loading={loading} buttonFunction={doChangePassword} />
                    </p>
                </form>
            </div>
        </div>
    );
}

export default withTranslation(['changePasswordPage', 'common'] )(ChangePasswordPage);
