/*
 *  RegisterPage.js
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/

import React from "react";
import './RegisterPage.css'
import Context from "../../context";
import { withTranslation } from 'react-i18next';
import Button from "../../components/Button";
import { toast } from "react-toastify";

const CreateNonUserPage = (props) => {
    const { t, showLogin } = props; 
    const [loading, setLoading] = React.useState(false);

    const doRegister = (event) => {
        setLoading(true)
        const errorMessageElementFirstName = document.getElementById('error-message-firstname');
        const errorMessageElementLastName = document.getElementById('error-message-lastname');
        const errorMessageElementCity = document.getElementById('error-message-city');
        const errorMessageElementZipcode = document.getElementById('error-message-zipcode');
        const errorMessageElementUser = document.getElementById('error-message-user');
        const errorMessageElementEmail = document.getElementById('error-message-mail');
        const errorMessageElementPasswordValid = document.getElementById('error-message-pwvalid');
        const errorMessageElementPasswordRepeat = document.getElementById('error-message-pwrepeat');
        

        // Clear previous error message
        errorMessageElementFirstName.textContent = '';
        errorMessageElementFirstName.style.display = 'none';

        // Clear previous error message
        errorMessageElementLastName.textContent = '';
        errorMessageElementLastName.style.display = 'none'; 

        // Clear previous error message
        errorMessageElementUser.textContent = '';
        errorMessageElementUser.style.display = 'none';

        // Clear previous error message
        errorMessageElementEmail.textContent = '';
        errorMessageElementEmail.style.display = 'none';

        // Clear previous error message
        errorMessageElementPasswordValid.textContent = '';
        errorMessageElementPasswordValid.style.display = 'none';

        // Clear previous error message
        errorMessageElementPasswordRepeat.textContent = '';
        errorMessageElementPasswordRepeat.style.display = 'none'; 

        var payload = {
            content     : "content",
            name        : "itemName",
            orgaId      : "orgaId",
            userId      : "userId",
            itemTypeId  : "itemTypeId", 
        };
        Context.api.post('/v1/nonusers', payload ).then( res => {
            setLoading(false)
            toast.success( t("NON_USER_WAS_CREATED") );
            props.showHomePage();
        }).catch( ( error ) => {
            setLoading(false)
            Context.alertError( error );
        });
    } 

    return (
        <div id="divRegisterOuter" className="stddlg-outer"> 
            <form>
                <div className="">
                    <div className="row" style={{ textAlign: 'left' }}>
                        <h1 style={{ textAlign: 'left' }}>{t("CREATE_NON_USER")}</h1>
                    </div>
                    <div className="row">
                    <div className="column"> 
                        <p>
                            <input className="loginfeld" placeholder={t("NON_USER_NAME")} type="text" name="name" id="name" required />
                        </p>
                        <div>
                        <span id="error-message-user" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <p> 
                            <input className="loginfeld" placeholder={t("NON_USER_CONTENT")} type="text" name="content" id="content" required /> {/* Changed to email type */}
                        </p>
                        <div>
                        <span id="error-message-mail" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <p>
                            <input className="loginfeld" placeholder={t("ITEM_TYPE")} type={"text"} name="text" id="text" required /> 
                            {/* This should be a select from the availaible item types */}
                        </p>
                        <div>
                            <span id="error-message-pwvalid" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                         
                        <br />
                        <p>
                            <Button buttonValue={t("CREATE_NON_USER")} loading={loading} buttonFunction={doRegister} />
                        </p> 
                    </div> 
                    </div>
                </div>
            </form>
        </div> 
    );
}

export default withTranslation(['createNonUserPage', 'common'] )(CreateNonUserPage);
