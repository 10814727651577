// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 *  HomePage.css
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
.homepage {

}

.imgTitle {
    width: 100%;
}

.card-image-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.card-profile {
    display: flex;
    padding: 5px;
    align-items: center;
    gap: 8px;
}


.card {
    margin-block: 1em;
}

.card-container {
    border: 1px solid black;
    border-radius: 15px;
    margin: 0.5em;
    padding-block: 1em;
}

.container {
    margin: auto !important;
}



.info-card-header {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    p {
        margin: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage.css"],"names":[],"mappings":"AAAA;;;;;;8EAM8E;AAC9E;;AAEA;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,QAAQ;AACZ;;;AAGA;IACI,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;AAC3B;;;;AAIA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB;QACI,SAAS;IACb;AACJ","sourcesContent":["/*\n *  HomePage.css\n *\n *  @author Martin Wolf\n *  \n *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)\n * ***************************************************************************/\n.homepage {\n\n}\n\n.imgTitle {\n    width: 100%;\n}\n\n.card-image-profile {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n}\n\n.card-profile {\n    display: flex;\n    padding: 5px;\n    align-items: center;\n    gap: 8px;\n}\n\n\n.card {\n    margin-block: 1em;\n}\n\n.card-container {\n    border: 1px solid black;\n    border-radius: 15px;\n    margin: 0.5em;\n    padding-block: 1em;\n}\n\n.container {\n    margin: auto !important;\n}\n\n\n\n.info-card-header {\n    display: flex;\n    flex-direction: column;\n    /* align-items: center; */\n    p {\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
