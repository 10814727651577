/*
 *  Navbar.js
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
import React, { useEffect, Suspense } from "react";
import Cookies from "js-cookie";
import './Navbar.css'
import ReactDOM from "react-dom";
import Context from "../context";
import {withTranslation} from "react-i18next";
import i18n from '../i18n';

import HomePage from "../pages/HomePage.js";
import RegisterPage from "../pages/users/RegisterPage.js";
import PasswordResetPage from "../pages/users/PasswordResetPage";
import ProfilePage from "../pages/users/ProfilePage";
import ChangePasswordPage from "../pages/users/ChangePasswordPage";
import LoginPage from "../pages/users/LoginPage";
import DeleteAccountPage from "../pages/users/DeleteAccountPage";
import ImprintPage from "../pages/ImprintPage";

import CreateOrganizationPage from "../pages/organizations/CreateOrganizationPage.js";


import backEndData, { menuAdmin, menuCommon } from '../components/backEndData.js';
import { Loading } from "../components/Loading.js";
import { ToastContainer } from "react-toastify";
import OrganizationPage from "../pages/organizations/OrganizationPage.js";
import OrganizationDetailPage from "../pages/organizations/OrganizationDetailPage.js";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars, faAngleDown, faHome } from '@fortawesome/free-solid-svg-icons'
import CreateNonUserPage from "../pages/nonUser/CreateNonUserPage.js";

import { Link,Navigate, Router, useNavigate } from "react-router-dom";
import api from "../hooks/api.js";
import { useAuth } from "../hooks/authContext.js";

const Navbar = (props) => {
    const { t } = props;
    const { isLoggedIn, logout, isAdmin } = useAuth();

    const [clicked, setClicked] = React.useState(false); // for the burger-menu show and hide
    const [menuClicked, setMenuClicked] = React.useState(false); // for the burger-menu show and hide
    const [user, setUser] = React.useState(useAuth.isLoggedIn); 
    const [admin, setAdmin] = React.useState(useAuth.isAdmin); 
    const [menuitems, setMenuitems] = React.useState(backEndData.menuCommon); 
    const navigate = useNavigate();  
    
    useEffect(() => {
        // Dynamically determine menu items based on login state
        console.log("NAV changes : ", isAdmin, isLoggedIn)
        if (isLoggedIn) {
            if (isAdmin) {
                setMenuitems([...backEndData.menuUser, backEndData.menuAdmin, ...backEndData.menuCommon]);
            } else {
                setMenuitems([...backEndData.menuUser, ...backEndData.menuCommon]);
            }
        } else {
            setMenuitems([...backEndData.menuGuest, ...menuCommon]);
        }
    }, [isLoggedIn, isAdmin]); // Re-run this effect only when these values change


    
    // Toggle the burger-menu
    const handleClick = () => {
        setClicked(!clicked);
    }

    const showSubmenu = ( event ) => {
        console.log( event.target.id )
        if ( document.getElementById( "submenu"+ event.target.id ) != null ) {
            document.getElementById( "submenu"+ event.target.id ).style.display = "block";
        } else {
            if ( document.getElementById( event.target.id ) != null ) {
                document.getElementById( event.target.id ).style.display = "block";
            }
        }
    }

    const hideSubmenu = ( event ) => {
        setMenuClicked(true); 
        if ( document.getElementById( "submenu"+ event.target.id ) != null ) {
            document.getElementById("submenu" + event.target.id).style.display = "none";
        } else {
            if ( document.getElementById( event.target.id ) != null ) {
                document.getElementById(event.target.id).style.display = "none";
            }
        }
    }

    const handleMenuClick = (event) => {
        setMenuClicked(!menuClicked);  
            if (menuClicked){
                showSubmenu(event)
            }
            else { 
                hideSubmenu(event);  
            } 
    }

    const doLogoff = () => {
        Context.username = "";
        Context.password = "";
        Cookies.set( 'user', "" );
        Cookies.set( 'pw', "" ); 
        Cookies.set( 'isAdmin', false ); 
        logout()
        setUser("");
        navigate("user/logout")
    }

    const doit = ( event ) => {
        if ( event.currentTarget.name ) {
            handleClick(); 
            hideSubmenu(event);
            execute[event.currentTarget.name]();
            console.log(execute[event.currentTarget.name])

        }
        else {
            if (menuClicked){
                showSubmenu(event)
            }
            else { 
                hideSubmenu(event);  
            }
        }
    } 

   const execute = {
        showHomePage : ()=>navigate("/"),
        showLoginPage : ()=>navigate("/user/login"),
        showRegisterPage : ()=>navigate("user/register"),
        showPasswordResetPage : ()=>navigate("user/password-reset"),
        showProfilePage : ()=>navigate("/user/profile"),
        showChangePasswordPage : ()=>navigate("/user/password-change"),
        showDeleteAccountPage : ()=>navigate("/user/delete-account"),
        doLogoff : doLogoff,
        // showImprint : showImprint
    };

    

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng); // Changes the language
        console.log(i18n.language)
    }

    return( 
        <nav className="NavbarItems">
            <ToastContainer closeOnClick /> 
            <div className="headerTop"> 
                <div className="lang-change">
                    <button className={i18n.language==='en_en'?"selected-language":""} onClick={() => changeLanguage('en_en')}>EN</button>   {/*  maybe turn this into a dropdown?*/}
                    <button className={i18n.language==='de_de'?"selected-language":""}  onClick={() => changeLanguage('de_de')}>DE</button>
                
                </div>
            </div> 
            <div className="side-elements">
                <div className="register-login">
                    {!isLoggedIn?
                        <div>
                             <Link to="/user/register" className="navbar-link">{t('Registrieren')}</Link>/
                             <Link to="/user/login" className="navbar-link">{t('Anmelden')}</Link>                              
                        </div>
                    :
                    <a href="" className="navbar-link" onClick={(e)=>{e.preventDefault(); doLogoff()}} >{t('Abmelden')}</a>
                    }
                    
                </div>
            </div>
            
            <div className="menu-icon" onClick={handleClick}> 
                <FontAwesomeIcon icon={clicked ? faTimes:faBars } /> 
            </div>
            <ul className={clicked ? 'nav-menu active' : 'nav-menu'}> {
                menuitems.map((item,index) => {
                    return (
                        <li id={index} key={index}>
                            <a id={index} className={`${item.cName}` } onClick={doit} name={item.url} 
                            // onMouseEnter={showSubmenu} 
                            onMouseLeave={hideSubmenu}
                            >
                                <span className={`nav-item-li`} id={index}>
                                {item.image? <FontAwesomeIcon icon={faHome} />:'' } 
                                    {t(item.title)} 
                                    {item.submenu?.length>0?<FontAwesomeIcon icon={faAngleDown} />:''}
                                    &nbsp;
                                </span>
                            </a> 
                            
                            <div id={"submenu"+index} className={clicked ? 'nav-submenu-div active' : 'nav-submenu-div'}
                                    onMouseEnter={showSubmenu} onMouseLeave={hideSubmenu}
                                    onClick={handleMenuClick}
                                    >
                                <ul id={index} className='nav-submenu'> {
                                    item.submenu?.map((subitem,subindex) => {
                                        return (
                                            <li id={index} key={index + subindex}>
                                                <a id={index} className={subitem.cName} onClick={doit} name={subitem.url}>
                                                    <span id={index} className={subitem.image} />
                                                    {t(subitem.title)}
                                                </a>
                                            </li>
                                        )
                                    })
                                } </ul>
                            </div>
                        </li>
                    )
                })
            } 
            <div className="side-elements">
                <div className="work-home"> 
                    <Link className="navbar-link" style={{"backgroundColor":"green"}} to={"/"}>HOME</Link>
                    <Link className="navbar-link" style={{"backgroundColor":"orange"}} to={isAdmin?"/admin":"/"}>{isAdmin?"ADMIN":"WORK"}</Link> 
                    </div>
            </div>  
            </ul> 
        </nav>
    );
}

export default withTranslation('common' )(Navbar);
