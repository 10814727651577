// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 *  App.css
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
{}`, "",{"version":3,"sources":["webpack://./src/pages/ImprintPage.css"],"names":[],"mappings":"AAAA;;;;;;8EAM8E;AAC9E,CAAC","sourcesContent":["/*\n *  App.css\n *\n *  @author Martin Wolf\n *  \n *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)\n * ***************************************************************************/\n{}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
