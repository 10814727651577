import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import "./Navbar.css"

const MainLayout = () => {
    return (
        <div>
            <div className="header-container">
                <a href="/">
                    <img className="company-logo" src="/hilfmir.logo.jpg" alt="Company Logo" />
                </a>
                <div id="divNavbar">
                    <Navbar />
                </div>
            </div>
            
            <div id="pageArea" style={{ height: "90%" }}>
                <Outlet />
            </div>
        </div>
    );
};

export default MainLayout;