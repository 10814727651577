import axios from "axios";
import Context from "../context";
import Cookies from "js-cookie";
import { useAuth } from "./authContext";

const createApi = () => {
    if (Context.backendURL === "") {
        Context.backendURL = "https://pff.ddnss.de:8081/";
        Context.api = axios.create({ baseURL: Context.backendURL });
    }
    return Context.api;
};

const get = async (endpoint, auth=false) => {
    const api = createApi(); 
    const options = auth
    ?
    {
        auth: {
            username: Cookies.get( 'user' ),
            password: Cookies.get( 'pw' ),
        },
    }
    :   
    {}
    console.log("api, ", options)
    return api.get(endpoint, options);
};

const post = async (endpoint, auth=false, body) => {
    const api = createApi();
    const options = auth
    ?
    {
        auth: {
            username: Cookies.get( 'user' ),
            password: Cookies.get( 'pw' ),
        },
    }
    :   
    {}
    console.log(endpoint, body, options)
    return api.post(endpoint, body, options);
};

const put = async (endpoint, auth=false, body) => {
    const api = createApi();
   
    const options = auth
    ?
    {
        auth: {
            username: Cookies.get( 'user' ),
            password: Cookies.get( 'pw' ),
        },
    }
    :   
    {}
    console.log(endpoint, body, options)
    return api.put(endpoint, body, options);
};

const del = async (endpoint, auth=false) => {
    const api = createApi();
    const options = auth
    ?
    {
        auth: {
            username: Cookies.get( 'user' ),
            password: Cookies.get( 'pw' ),
        },
    }
    :   
    {}
    return api.delete(endpoint, options );
};

// Helper to set credentials
const setCredentials = (username, password, isAdmin=false) => {

    Context.username = username;
    Context.password = password;
    Cookies.set( 'user', username );
    Cookies.set( 'pw', password );
    Cookies.set( 'isAdmin', isAdmin );
    Cookies.set( 'language',"de_de");
    console.log("setCredentials", username, password, isAdmin)      
};


export default {get, put, post, del, setCredentials}