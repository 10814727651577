/*
 *  LoginPage.js
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
import React from "react";
import './LoginPage.css'
import Context from "../../context";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Button from "../../components/Button";


const LogoutPage = (props) => { 
    
    Context.username = "";
    Context.password = "";
    Cookies.set( 'user', "" );
    Cookies.set( 'pw', "" ); 
    Cookies.set( 'isAdmin', false );
    return (
        <div id="divLoginOuter" className="stddlg-outer">
            <div id="divLogin" className="stddlg">
                <span>Du wurdest abgemeldet. Bis bald :-)</span>
            </div>
        </div>
        );
}

export default withTranslation(['loginPage', 'common'])(LogoutPage);
