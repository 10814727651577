/******************************************************************************
 *                                                                            *
 *  ProfilePage.js                                                            *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React, {useEffect} from "react";
// import './ProfilePage.css'
import Context from "../../context";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import { useParams , Link, useNavigate} from 'react-router-dom';
import { Loading } from "../../components/Loading";
import api from "../../hooks/api";

const OrganizationDetailPage = (props) => {
    const { t } = props;
    const {id} = useParams()
    console.log(props)
    const [data, setData] = React.useState({});
    const [itemTypes, setItemTypes] = React.useState([]);
    const [nonUsers, setNonUsers] = React.useState([]);
    const [edit, setEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    console.log(props.organization)

    useEffect( () => {  
        setLoading(true)
        api.get('v1/organizations/'+id , true).then( res => { 
             setData( res.data );
             console.log(res.data)
        }).catch( ( error ) => {
            setData([])
            setLoading(false)

            Context.alertError( error );
        });

        api.get('v1/itemtypes/by-orgaid/'+id ,true).then( res => { 
            //  setData( res.data );
                setItemTypes(res.data)
                console.log(res.data)
        }).catch( ( error ) => {
            setItemTypes([])
            setLoading(false)
            Context.alertError( error );
        });

        api.get('v1/nonusers/by-orgaid/'+id , true).then( res => { 
             setNonUsers( res.data );
             console.log(res.data)
             setLoading(false)  
        }).catch( ( error ) => {
            setNonUsers( []);
            setLoading(false)            
            Context.alertError( error );
        });
    }, []);

    const changeLanguage = (language) => {
        setData( Object.assign( {}, data, {language : language})  );
        Context.language = language;
        Cookies.set( 'language', language );
        i18n.changeLanguage( Context.language );
    }; 


    const doUpdateUser = () => {
        setLoading(true)
        if ( data.username === "" ) {
            setLoading(false);
            toast.warning( t("ENTER_USERNAME"));
            return;
        }

        const email = data.email;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            setLoading(false);
            toast.warning( t("MAILFORMAT_INVALID") )
            return;
        }

        api.put('updateUser', true, data ).then( () => {
            setLoading(false);
            toast.success( t("PROFILE_WAS_SAVED"))
        }).catch( ( error ) => {
            setLoading(false);
            Context.alertError( error );
        });
    }



    return (
        <>
        {loading
        ?
        <Loading />
        :
        <div className="home-iframe body"> 
                <div class='page-content-1'> 
                    <div class='container'> 
                        <Tabs
                        defaultActiveKey="organization"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        >
                            <Tab eventKey="organization" title={t("ORGANIZATION_DETAILS")}> 
                                
                                <div>
                                    <div>
                                        <b>Name</b>
                                        <p>{data.name}</p> 
                                        <b>Description</b>
                                        <p>{data.description}</p> 
                                    </div>
                                </div>
                                <p className="text-start">
                                    <Link className="btn btn-primary" to={"/admin/modify-organization/"+data.id}>{t("MODIFY_ORGANIZATION")}</Link> 
                                </p>
                            </Tab>
                            <Tab eventKey="authorities" title={t("ITEM_TYPES")}> 
                                <p className="text-end">
                                    <button className="btn btn-primary"  onClick={props.showCreateItemTypePage}>{t("CREATE_ITEM_TYPE")}</button>
                                </p>
                                <div class="table-responsive">
                                    <table class="table">
                                        {/* <caption>List of users</caption> */}
                                        <thead>
                                            <tr>
                                            <th scope="col">#</th> 
                                            <th scope="col">{t("ITEM_TYPE_NAME")}</th>
                                            {/* <th scope="col">{t("ORGANIZATION_DESCRIPTION")}</th> */}
                                            <th scope="col">{t("ACTION")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemTypes?.map((itemType, index)=>(
                                                <tr>
                                                    <th scope="row">{index}</th> 
                                                    <td>{itemType.name}</td>
                                                    <td>Del Modify</td> 
                                                </tr>
                                            ))} 
                                        </tbody>
                                    </table>
                                </div>
                            </Tab>
                            <Tab eventKey="users" title={t("NON-USERS")}> 
                            <p className="text-end">
                                    <button className="btn btn-primary" onClick={props.showCreateNonUserPage} >{t("CREATE_NON_USER")}</button>
                                </p>
                            <div class="table-responsive">
                                    <table class="table">
                                        {/* <caption>List of users</caption> */}
                                        <thead>
                                            <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{t("NAME")}</th>
                                            <th scope="col">{t("CONTENT")}</th>
                                            <th scope="col">{t("ACTION")}</th>
                                             </tr>
                                        </thead>
                                        <tbody>
                                            {nonUsers?.map((nonUser, index)=>(
                                                <tr>
                                                    <th scope="row">{index}</th> 
                                                    <td>{nonUser.name}</td>
                                                    <td>{nonUser.content}</td>
                                                    <td>Del Modify</td> 
                                                </tr>
                                            ))}  
                                        </tbody>
                                    </table>
                                </div>
                            </Tab>
                        </Tabs> 
                            
                     </div>
                
                </div>

        </div>
        }

            
            
        </>
    );
}

export default withTranslation(['admin', 'common'] )(OrganizationDetailPage);
