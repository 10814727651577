import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import i18n from "../i18n";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import Layout from "../layout/Layout";
import HomePage from "../pages/HomePage"; 
import axios from "axios";

import Context from "../context";
import { ToastContainer, toast } from "react-toastify"; 
import UserRoutes from "../routes/UserRoutes";
import AdminRoutes from "../routes/AdminRoutes";
import { AuthProvider } from "../hooks/authContext";
import api from "../hooks/api";
import ActivationHandler from "../pages/activationHandler";
// import { AuthProvider } from "./context/AuthContext";


const App = (props) => {
    const [loading, setLoading] = useState(true);
    const { t, showLogin, showRegister} = props;

    // Fetch user from cookie if present
    const [user, setUser] = useState( Cookies.get( 'user' ))
    var userId = Cookies.get( 'id' );
    if ( user && ( user !== "" )) {
        if ( Context.username !== user) {
            Context.username = user;
            Context.password = Context.decrypt( Cookies.get( 'pw' ));
            Context.language = Cookies.get( 'language' );
            i18n.changeLanguage( Context.language );
        }
    } else {
        var lng = navigator.language.toLowerCase().replaceAll( "-", "_");
        switch ( lng ) {
            case "de" : lng = "de_de"; break;
            case "en" : lng = "en_gb"; break;
            case "en_us" : lng = "en_gb"; break;
            case "es" : lng = "es_es"; break;
            case "fr" : lng = "fr_fr"; break;
            default:
        }
        if ( Context.language !== lng) {
            Context.language = lng;
            i18n.changeLanguage( Context.language );
        }
    }

    useEffect(() => {
        const userCookie = Cookies.get("user");
        if (userCookie) {
            setUser({
                username: userCookie,
                password: Cookies.get("pw"),
                language: Cookies.get("language"),
            });
            i18n.changeLanguage(Cookies.get("language"));
        } else {
            i18n.changeLanguage("en");
        }
        setLoading(false);
    }, []);
    

    

    // Set hostnames for front- and backend
    if ( Context.backendURL === "" ) {
        var hostname = window.location.hostname;
        //alert(hostname);
        Context.backendURL = 'https://'+ 'pff.ddnss.de'+ ':8081/';
        Context.frontendURL = 'http://pff.ddnss.de:3000/';
        Context.api = axios.create({
            baseURL: Context.backendURL
        })
    };

    // If this is a call for an account-activation, then retrieve key and activate the account
    if ( "/activate-account" === window.location.pathname ) {
        var activationKey = Context.getQueryParams( window.location.href.split('?')[1] ).get( "activationKey" );
        const o = {
            activationKey: activationKey
        }
        api.post('/v1/users/activate-account',false, o ).then( () => {
            toast.warning( i18n.t("common:ACCOUNT_WAS_ACTIVATED") );
            window.location = Context.frontendURL;
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    // If this is a call for a password-reset, then retrieve key and show reset-message
    if ( "/resetPassword" === window.location.pathname ) {
        let activationKey = Context.getQueryParams( window.location.href.split('?')[1] ).get( "activationKey" );
        const o = {
            activationKey: activationKey
        }
        Context.api.post('resetPassword', o ).then( res => {
            toast.warning( i18n.t("common:PASSWORD_WAS_RESET_TO")+ ":\n\n"+ res.data+ "\n\n"+ i18n.t("common:START_MESSAGE") );
            window.location = Context.frontendURL;
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    // If this is a call for an account-deletion, then retrieve key and show deletion-message
    if ( "/deleteAccount" === window.location.pathname ) {
        let activationKey = Context.getQueryParams( window.location.href.split('?')[1] ).get( "activationKey" );
        const o = {
            activationKey: activationKey
        }
        Context.api.post('deleteAccount', o ).then( () => {
            Cookies.set( 'user', '' );
            Cookies.set( 'pw', '' );
            Context.username = '';
            Context.password = '';
            toast.warning( i18n.t("common:ACCOUNT_HAS_BEEN_DELETED"));
            window.location = Context.frontendURL;
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    if (loading) return <div>Loading...</div>;

    return (
        <AuthProvider>
            <Router>
                <ToastContainer closeOnClick />
                <Routes> 
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} /> 
                        <Route path="user/*" element={<UserRoutes />} />
                        <Route path="admin/*" element={<AdminRoutes />} />
                        <Route path="*" element={<p>404</p>} />
                    </Route>  
                </Routes>
            </Router> 
        </AuthProvider>
        
    );
};

export default App;
