import React from 'react';
import "./Button.css";

const Button = ({buttonFunction, loading, buttonValue, style}) => {

  console.log(loading)
    // const { t } = props;

  return (
    // <input type="button"  onClick=value=/>
    <buttton className="button-component" style={style} onClick = { () => buttonFunction() } >
        <div className='button-value' style={!loading? { display:"block"}: {display:"none"}} >{buttonValue}</div>
        <div className='button-loading' style={loading? { display:"block"}: {display:"none"}}></div>
    </buttton>
  )
}

export default Button

{/* <input type="button" className="reset-button" onClick={ () => buttonFunction() } value={t("SEND_RESET_MAIL")}/> */}
