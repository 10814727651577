// import "./data/menuadmin.json" assert {type:'json'};
// import "./data/menucommon.json";
// import "./data/menuguest.json";
// import "./data/menuuser.json";
 
export const menuAdmin = require("./data/menuadmin.json")
export const menuCommon = require("./data/menucommon.json")
export const menuUser = require("./data/menuuser.json")
export const menuGuest = require("./data/menuguest.json")
export const apiMenuItems = [
    {
      "title": "",
      "url": "showHomePage",
      "cName": "nav-item",
      "image": "fas fa-home",
      "submenu": []
    },
    {
      "title": "Anmelden",
      "url": "showLoginPage",
      "cName": "nav-item",
      "image": "",
      "submenu": []
    },
    {
      "title": "Mitmachen",
      "url": "",
      "cName": "nav-item",
      "image": "",
      "submenu": [
        {
          "title": "Registrieren",
          "url": "showRegisterPage",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Passwort zurücksetzen",
          "url": "showPasswordResetPage",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Nettiquette",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Verifizierung - Leitfaden",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Verifizierung - DSGVO-Verpflichtungserklärung",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Verifizierung - DSGVO-Erklärung",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        }
      ]
    },
    {
      "title": "Gruppen",
      "url": "",
      "cName": "nav-item",
      "image": "",
      "submenu": [
        {
          "title": "Deutschland",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Baden-Württemberg",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Bayern",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Berlin",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Brandenburg",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Bremen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Hamburg",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Hessen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Mecklenburg-Vorpommern",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Niedersachsen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Nordrhein-Westpfahlen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Rheinland-Pfalz",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Saarland",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Sachsen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Sachsen-Anhalt",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Schleswig-Holstein",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Thüringen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        }
      ]
    },
    {
      "title": "Aktionen",
      "url": "",
      "cName": "nav-item",
      "image": "",
      "submenu": [
        {
          "title": "Veranstaltungen und Termine",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Themen und Arbeitsgruppen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Kampagnen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Abstimmungen - Orts-/Regionalgruppenebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Abstimmungen - Landesebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Abstimmungen - Bundesebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wahlen - Orts-/Regionalgruppenebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wahlen - Landesebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wahlen - Bundesebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wissen & Fakten",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        }
      ]
    },
    {
      "title": "Über uns",
      "url": "",
      "cName": "nav-item",
      "image": "",
      "submenu": [
        {
          "title": "Organisationen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wozu diese Seite",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Seitenbetreiber",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wer wir sind",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Impressum",
          "url": "showImprint",
          "cName": "nav-submenu-item",
          "image": ""
        }
      ]
    },
    {
      "title": "Kontakt / Hilfe",
      "url": "",
      "cName": "nav-item",
      "image": "",
      "submenu": [
        {
          "title": "Video Tutorials",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "FAQ",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Kontaktformular",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Newsletter",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Techblog",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Intro",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Einführung",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        }
      ]
    }
  ];
  const users = [
    {
        "id": 3,
        "username": "Celine",
        "password": "$2a$10$SrX.a7.SVTljMWQrnz6lfu9zmidi.hRaNsqC4XoeSQaYlCQhY/Z4a",
        "enabled": true,
        "lastname": null,
        "firstname": null,
        "email": "celinenordmann96@gmail.com",
        "postcode": null,
        "city": null,
        "address": null,
        "telephone": null,
        "mobile": null,
        "idcardnumber": null,
        "lastlogin": "2021-06-04T15:08:50.925Z",
        "lastfaillogin": null,
        "lastnotifications": null,
        "faillogincount": 0,
        "shownotifications": "D",
        "showintro": false,
        "showhelp": true,
        "showclearname": false,
        "showcontact": false,
        "language": "de_de",
        "activationkey": "4zVjC91kRI4uVTab",
        "currentUsername": null,
        "oldPassword": null,
        "mailSubject": null,
        "mailBody": null,
        "orgaid": 0
    },
    {
        "id": 4,
        "username": "testtest",
        "password": "$2a$10$n/hKp3Cn12q4coRsPcvuJumhPlzBW9d0vcTKpsZ9.P.TWmTg57MM2",
        "enabled": true,
        "lastname": null,
        "firstname": null,
        "email": "claus.hansen@digistreet.eu",
        "postcode": null,
        "city": null,
        "address": null,
        "telephone": null,
        "mobile": null,
        "idcardnumber": null,
        "lastlogin": "2021-06-04T15:10:54.348Z",
        "lastfaillogin": null,
        "lastnotifications": null,
        "faillogincount": 0,
        "shownotifications": "D",
        "showintro": false,
        "showhelp": true,
        "showclearname": false,
        "showcontact": false,
        "language": "de_de",
        "activationkey": "BZNhgPfbymWb9H3M",
        "currentUsername": null,
        "oldPassword": null,
        "mailSubject": null,
        "mailBody": null,
        "orgaid": 0
    },
    {
        "id": 5,
        "username": "tonio",
        "password": "$2a$10$L8fnMwUtzJRu5B6wVXTWW.iMKcW/nyOwRmUeUVoXJGB.jWDdYSkJS",
        "enabled": false,
        "lastname": null,
        "firstname": null,
        "email": "tonio.suessdorf@gmail.com",
        "postcode": null,
        "city": null,
        "address": null,
        "telephone": null,
        "mobile": null,
        "idcardnumber": null,
        "lastlogin": null,
        "lastfaillogin": null,
        "lastnotifications": null,
        "faillogincount": 0,
        "shownotifications": "D",
        "showintro": false,
        "showhelp": true,
        "showclearname": false,
        "showcontact": false,
        "language": "de_de",
        "activationkey": "xl5HHiRjqzESc6h0",
        "currentUsername": null,
        "oldPassword": null,
        "mailSubject": null,
        "mailBody": null,
        "orgaid": 0
    },
    {
        "id": 6,
        "username": "netacy",
        "password": "$2a$10$bFN1oa/hYoElu8WLvUCY4OUe/O3bqkyBW2zoV2aeYA00dEkLfwPd2",
        "enabled": false,
        "lastname": null,
        "firstname": null,
        "email": "netacy2015@gmail.com",
        "postcode": null,
        "city": null,
        "address": null,
        "telephone": null,
        "mobile": null,
        "idcardnumber": null,
        "lastlogin": "2024-10-25T19:03:45.130269Z",
        "lastfaillogin": "2024-11-14T03:13:05.135243Z",
        "lastnotifications": null,
        "faillogincount": 9,
        "shownotifications": "D",
        "showintro": false,
        "showhelp": true,
        "showclearname": false,
        "showcontact": false,
        "language": "de_de",
        "activationkey": "V76KTFGvrkNchoW1",
        "currentUsername": null,
        "oldPassword": null,
        "mailSubject": null,
        "mailBody": null,
        "orgaid": 0
    },
    {
        "id": 7,
        "username": "simsala",
        "password": "$2a$10$.s15rhkdax3gykpYKOBGoOxseKKyw5Y5A1W9y/IJQXmLb2ag5qNN2",
        "enabled": false,
        "lastname": null,
        "firstname": null,
        "email": "simsala@begin.cm",
        "postcode": null,
        "city": null,
        "address": null,
        "telephone": null,
        "mobile": null,
        "idcardnumber": null,
        "lastlogin": null,
        "lastfaillogin": null,
        "lastnotifications": null,
        "faillogincount": 0,
        "shownotifications": "D",
        "showintro": false,
        "showhelp": true,
        "showclearname": false,
        "showcontact": false,
        "language": "de_de",
        "activationkey": "Xt0oDVu3KhS3z0Ql",
        "currentUsername": null,
        "oldPassword": null,
        "mailSubject": null,
        "mailBody": null,
        "orgaid": 0
    },
    {
        "id": 8,
        "username": "a",
        "password": "$2a$10$eJ18Q/2v4RC1/owCVTgMmu./WTO2ONTxOk.SVbWdvvCJUI2vSut2y",
        "enabled": true,
        "lastname": "test",
        "firstname": "222",
        "email": "fantaf_blue@yahoo.com",
        "postcode": "81373",
        "city": null,
        "address": null,
        "telephone": null,
        "mobile": null,
        "idcardnumber": null,
        "lastlogin": "2024-11-23T12:19:39.740408Z",
        "lastfaillogin": null,
        "lastnotifications": null,
        "faillogincount": 0,
        "shownotifications": "D",
        "showintro": false,
        "showhelp": true,
        "showclearname": false,
        "showcontact": false,
        "language": "de_de",
        "activationkey": "pTHgvYPMIS3Xwj02",
        "currentUsername": null,
        "oldPassword": null,
        "mailSubject": null,
        "mailBody": null,
        "orgaid": 0
    },
    {
        "id": 9,
        "username": "anna",
        "password": "$2a$10$RFs.OzCv8LNtECIO0nwlYeUOi0vILUqH58Tqu4XCpcSe//3I/NKv6",
        "enabled": true,
        "lastname": null,
        "firstname": null,
        "email": "dev@sunflower-care.org",
        "postcode": null,
        "city": null,
        "address": null,
        "telephone": null,
        "mobile": null,
        "idcardnumber": null,
        "lastlogin": "2024-11-11T15:47:45.911614Z",
        "lastfaillogin": null,
        "lastnotifications": null,
        "faillogincount": 0,
        "shownotifications": "D",
        "showintro": false,
        "showhelp": true,
        "showclearname": false,
        "showcontact": false,
        "language": "de_de",
        "activationkey": "ykQgCgc5efh6CO3P",
        "currentUsername": null,
        "oldPassword": null,
        "mailSubject": null,
        "mailBody": null,
        "orgaid": 0
    },
    {
        "id": 10,
        "username": "sir",
        "password": "$2a$10$GRZAhmJtvrnp5bkqqCf8tOS9FfswB.C4dw5VV.rD6eWknSuh1EtzG",
        "enabled": true,
        "lastname": "Croc",
        "firstname": "Sir",
        "email": "sir.crocodile2016@gmail.com",
        "postcode": null,
        "city": "Berline",
        "address": null,
        "telephone": null,
        "mobile": null,
        "idcardnumber": null,
        "lastlogin": "2024-11-27T21:23:29.072762Z",
        "lastfaillogin": null,
        "lastnotifications": null,
        "faillogincount": 0,
        "shownotifications": "D",
        "showintro": false,
        "showhelp": true,
        "showclearname": false,
        "showcontact": false,
        "language": "de_de",
        "activationkey": "uymyiflaYEhYVE2E",
        "currentUsername": null,
        "oldPassword": null,
        "mailSubject": null,
        "mailBody": null,
        "orgaid": 0
    },
    {
        "id": 2,
        "username": "espressonerd",
        "password": "$2a$10$YJMnrbgOG24wMq00x3qrC.3V6DB2jJtQ3jzhwt/AFu8rV603NiEEq",
        "enabled": true,
        "lastname": "Hansen",
        "firstname": "Claus",
        "email": "info@claus-hansen.de",
        "postcode": null,
        "city": null,
        "address": null,
        "telephone": null,
        "mobile": null,
        "idcardnumber": null,
        "lastlogin": "2024-11-25T12:00:30.398911Z",
        "lastfaillogin": null,
        "lastnotifications": null,
        "faillogincount": 0,
        "shownotifications": "D",
        "showintro": false,
        "showhelp": true,
        "showclearname": false,
        "showcontact": false,
        "language": "de_de",
        "activationkey": "KEvmDFasAPjQtG6D",
        "currentUsername": null,
        "oldPassword": null,
        "mailSubject": null,
        "mailBody": null,
        "orgaid": 0
    },
    {
        "id": 1,
        "username": "Martin",
        "password": "$2a$10$mnuet4UPFVZGkr6lwACHdeXePPEVXYTePkET1PughW0f7zxvp2vyy",
        "enabled": true,
        "lastname": null,
        "firstname": null,
        "email": "mw@wolf-itc.de",
        "postcode": null,
        "city": null,
        "address": null,
        "telephone": null,
        "mobile": null,
        "idcardnumber": null,
        "lastlogin": "2024-11-27T19:48:03.516683Z",
        "lastfaillogin": "2024-11-27T20:27:38.872817Z",
        "lastnotifications": null,
        "faillogincount": 1,
        "shownotifications": "D",
        "showintro": false,
        "showhelp": true,
        "showclearname": false,
        "showcontact": false,
        "language": "de_de",
        "activationkey": "kcinctq9e9MP1EBS",
        "currentUsername": null,
        "oldPassword": null,
        "mailSubject": null,
        "mailBody": null,
        "orgaid": 0
    }
]
export default {
    apiMenuItems , menuAdmin, menuCommon, menuGuest , menuUser , users  
}