import React, { useEffect, useState } from "react";
import Context from "../context";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../hooks/api";
import { Loading } from "../components/Loading";
import { withTranslation } from "react-i18next";

const ActivationHandler = ({ action }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Loading state
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        const activationKey = searchParams.get("activationKey");
        if (!activationKey) {
            Context.alertError("Invalid or missing activation key.");
            setErrorMessage("Invalid or missing activation key.")
            setLoading(false); // Stop loading
            // navigate(-1);
            return;
        }

        const apiConfig = {
            activate: { method: "post", path: "v1/users/activate-account" },
            reset: { method: "post", path: "v1/users/reset-password" },
            delete: { method: "delete", path: `v1/users/${activationKey}` },
        }[action];

        if (!apiConfig) {
            Context.alertError("Invalid action type.");
            setErrorMessage("Invalid action type.")
            setLoading(false); // Stop loading 
            return;
        }

        const payload = { activationKey };

        // Start API request
        api[apiConfig.method](apiConfig.path, action === "delete" ? null : payload)
            .then((res) => {
                const messages = {
                    activate: "Account successfully activated.",
                    reset: `Password reset to: ${res.data}`,
                    delete: "Account deleted successfully.",
                };
                toast.success(messages[action]);
                navigate("/");
            })
            .catch((err) => {
                const errorMessages = {
                    activate: "Failed to activate the account. Please try again.",
                    reset: "Failed to reset the password. Contact support if the issue persists.",
                    delete: "Failed to delete the account. Ensure the key is valid or contact support.",
                };
                Context.alertError(errorMessages[action] || "An unknown error occurred.");
                setErrorMessage(errorMessages[action] || "An unknown error occurred.")
                // navigate(-1);
            })
            .finally(() => setLoading(false)); // Stop loading once API call is done
    }, [action, searchParams, navigate]);

    if (loading) {
        return <Loading />; // Use your loading component while waiting for the API response
    }

    return (
        errorMessage
        ?
        <div style={{ textAlign: "center", padding: "20px" }}>
            <h2>Error happened during request</h2>
            <p>{errorMessage}</p>
            <p>If this message persists, please contact support.<a href="" onClick={()=>{navigate(-1);}}>Return</a></p>

        </div>
        :
        <div style={{ textAlign: "center", padding: "20px" }}>
            <h2>Processing complete</h2>
            <p>Your request has been processed.</p>
        </div>
    );
};

export default withTranslation(['common'] )(ActivationHandler); 