// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const user = Cookies.get("user");
        setIsLoggedIn(!!user); // Set to true if user exists
        const admin = Cookies.get("isAdmin");
        setIsAdmin(admin === "true"); // Explicitly check for "true"
        setLoading(false); // Mark as initialized
    }, []);

    const login = (user) => {
        Cookies.set("user", user);
        setIsLoggedIn(true);
    };

    const setAdmin = () => {
        Cookies.set("isAdmin", true);
        setIsAdmin(true);
    };

    const logout = () => {
        Cookies.remove("isAdmin");
        Cookies.remove("user");
        setIsLoggedIn(false);
        setIsAdmin(false);
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, isAdmin, login, logout, setAdmin, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
